import { Card } from "./Card"
import { Parallax } from "react-parallax"


export const Services = () => {

  return (
    <div id="services" class="tm-section">
            
      <div class="tm-section-col tm-content">
          <h2 class="tm-text-primary content-center">Palvelut</h2>
          <p>   
            Annan ohjausta henkilökohtaisesti lähitapaamisena Tampereella tai etänä Teamsin välityksellä.
            Voit varata ajan yksittäiselle koulutus- ja uraohjaustapaamiselle tai voit hankkia itsellesi ”Oma opo”- palvelun koko vuodeksi, jolloin voit saada vuoden ajan ohjausta niin usein kuin koet itse tarpeelliseksi.
          </p>
                         
          <div class="tm-row">
            <Card 
                header="Yksittäiset ohjauskerrat"
                content="Henkilökohtaista koulutus-ja uraohjausta asiakkaan tarpeen mukaan.
                        n.1,5h kestävät ohjauskerrat ovat pääsääntöisesti Tampereella.
                        Etänä tapaaminen onnistuu myös hyvin.
                        95€/kerta (seuraavat kerrat puoleen hintaan!)
                        Ota yhteyttä!"
                price="95€"
                icon="../icons/library.png"
            />
            <Card 
                header="Ryhmäohjaus"
                content="Ryhmälle räätälöity 'opon tunti' esim.urheilijan koulutusmahdollisuuksista kilpaurheilun rinnalla tai urheilu-uran päätyttyä. 
                        Kysy lisää!"
                price="" 
                icon= "../icons/education.png"
            />
            <Card 
                header="Oma opo -palvelu"
                content="'Oma opo vuodeksi!' Tässä palvelussa saat opinto-ohjaajan palvelut jopa vuodeksi käyttöösi. Voit olla yhteydessä niin usein kuin haluat.
                          200€/ vuosi
                          Millainen tarve sinulla olisi? Ota yhteyttä, niin jutellaan lisää!"
                price="200€/vuosi" 
                icon= "../icons/education.png"
            />
            
          </div>                
      </div>   

      <Parallax bgImage="img/img_8.jpeg"  strength={500} >    
          <img class="tm-section-col tm-parallax" src="img/img_8.jpeg" alt="intro"></img>
      </Parallax>
    </div> 
  )
}
